<ng-container *ngIf="(loading$ | async) === false">
  <app-header-v2 [logoUrl]="(campaignMeta$ | async)?.logo?.['96p']"
    [logoAlt]="(campaignMeta$ | async)?.name ? (campaignMeta$ | async)?.name + ' Logo' : 'Company Logo'">
    <span class="text-black mr-3">Already have an account?</span>
    <button type="button" [routerLink]="['/auth/login']" queryParamsHandling="preserve" class="btn campaign-btn"
      [ngClass]="{'campaign-btn-color': (campaignMeta$ | async)?.color, 'btn-danger': !(campaignMeta$ | async)?.color }">Log
      In</button>
  </app-header-v2>
</ng-container>

<div class="row bg-radial align-items-center campaign-background"
  [ngClass]="{'campaign-background-overlay': (campaignMeta$ | async)?.slug}">
  <div class="col-md-8 offset-md-2" [ngClass]="{'box-shadow': (campaignMeta$ | async)?.color}">
    <div class="row">
      <div class="col-md-6 align-items-center d-flex" [ngClass]="{'vertical-separator-right': (campaignMeta$ | async)?.color,
      'overlay-gradient': (campaignMeta$ | async)?.color
    }">
        <app-features [color]="(campaignMeta$ | async)?.color ? 'white' : null"
          [style.color]="(campaignMeta$ | async)?.color ? 'white' : null"
          [heading]="(campaignMeta$ | async)?.name ? 'Join the ' + (campaignMeta$ | async)?.name + ' affiliate program today!' : 'Get actionable results from online marketing in one place'">
          <span id="feature-1" *ngIf="!(campaignMeta$ | async)?.name">Register for free and enjoy dedicated,
            personalized service.</span>
          <span id="feature-1" *ngIf="(campaignMeta$ | async)?.name">Benefit from a direct partnership with
            {{(campaignMeta$ | async)?.account_name}}.</span>

          <span id="feature-2" *ngIf="!(campaignMeta$ | async)?.name">Get started with a cloud-based platform and your
            own personal account manager.</span>
          <span id="feature-2" *ngIf="(campaignMeta$ | async)?.name">Connect with {{(campaignMeta$ | async)?.name}} for
            tailored growth solutions.</span>

          <span id="feature-3" *ngIf="!(campaignMeta$ | async)?.name">Join a trusted network where your success is our
            mission, with quick and reliable support.</span>
          <span id="feature-3" *ngIf="(campaignMeta$ | async)?.name">Join the {{(campaignMeta$ | async)?.name}}
            affiliate program now.</span>
        </app-features>
      </div>

      <div class="col-md-6" [ngClass]="{'bg-white': (campaignMeta$ | async)?.color}">
        <div class="login-box ml-auto" [ngClass]="{'box-shadow-none': (campaignMeta$ | async)?.color}">
          <div class="login-box-header pt-4">
            <h2 class="text-black pt-2 text-center">Publisher Sign Up</h2>
          </div>

          <div class="login-box-body">
            <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label>First Name</label>
                <input formControlName="firstName" type="text" class="form-control" placeholder="First Name"
                  [class.is-invalid]="submitted && signUpFormControls.firstName.errors" />
                <div *ngIf="submitted && signUpFormControls.firstName.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.firstName.errors.required">First Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <label>Last Name</label>
                <input formControlName="lastName" type="text" class="form-control" placeholder="Last Name"
                  [class.is-invalid]="submitted && signUpFormControls.lastName.errors" />
                <div *ngIf="submitted && signUpFormControls.lastName.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
              <div class="form-group mb-4">
                <label>Email</label>
                <input formControlName="email" type="text" class="form-control" placeholder="Email"
                  [class.is-invalid]="submitted && signUpFormControls.email.errors" />
                <div *ngIf="submitted && signUpFormControls.email.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.email.errors.required">Email is required</div>
                  <div *ngIf="signUpFormControls.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Password</label>
                <input formControlName="password" type="password" class="form-control" placeholder="Password"
                  [class.is-invalid]="submitted && signUpFormControls.password.errors" />
                <div *ngIf="submitted && signUpFormControls.password.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.password.errors.required">Password is required</div>
                  <div *ngIf="signUpFormControls.password.errors.capital">
                    Password should contain a capital letter<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.small">
                    Password should contain a small letter<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.number">
                    Password should contain a number<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.minlength">
                    Password should contain 8 or more characters<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.special">
                    Password should contain a special character ($&#64;!%*?&)
                  </div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Re-enter Password</label>
                <input formControlName="confirmPassword" type="password" class="form-control"
                  placeholder="Re-enter Password"
                  [class.is-invalid]="submitted && signUpFormControls.confirmPassword.errors" />
                <div *ngIf="submitted && signUpFormControls.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="signUpFormControls.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label class="control control-outline control-primary control--checkbox mt-4"
                  title="You need to read the terms and conditions before you can accept them">
                  I agree to the MCANISM <a target="_blank" [routerLink]="['/legal/terms-and-conditions']" href="">terms
                    and
                    conditions</a>

                  <input type="checkbox" formControlName="agreeToTermAndConditions" />

                  <div class="control__indicator"
                    [class.border-invalid]="submitted && signUpFormControls.agreeToTermAndConditions.errors">
                  </div>
                </label>
              </div>

              <div class="form-group mb-4">
                <re-captcha
                  formControlName="recaptcha"
                  [siteKey]="recaptchaSiteKey"
                  [class.is-invalid]="submitted && signUpFormControls.recaptcha.errors">
                </re-captcha>
                <div *ngIf="submitted && signUpFormControls.recaptcha.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.recaptcha.errors.required">Please complete the reCAPTCHA</div>
                </div>
              </div>

              <div class="w-100">
                <button type="submit" class="btn w-100 d-flex align-items-center justify-content-center"
                  [ngClass]="{'campaign-btn-color': (campaignMeta$ | async)?.color, 'btn-primary': !(campaignMeta$ | async)?.color }">
                  <img src="/assets/icons/circle-check.svg" alt="loader" class="mr-2" />
                  Sign Up!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer-v2></app-footer-v2>
