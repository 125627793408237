import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { APP_TITLE } from 'src/app/config/constant';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { NetworkIndicatorService } from 'src/app/shared/services/network-indicator.service';
import { TitleService } from 'src/app/shared/services/title-service';
import { DecoratorService } from 'src/app/shared/services/decorator.service';

import { Settings } from 'luxon';
import { filter } from 'rxjs/operators';

// Set the defaults.
Settings.defaultZone = 'UTC';
Settings.defaultLocale = 'en-US';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  route: string;
  params: string[];
  isLogin = false;
  title = APP_TITLE;

  public isNetworkIndicatorActive = false;

  private async verifyIncompletePublisherProfile() {
    const { account, finance } = await this.coreService.isIncompletePublisherProfile();
    if (account || finance) {
      const alertResult = await this.coreService.alert(
        'Incomplete profile',
        'Please fill out all the required fields on your profile.',
        {
          icon: 'warning',
          confirmButtonText: 'Edit Profile',
          showCancelButton: false,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        },
      );
      if (alertResult.value) {
        let url = '/setting/account';
        let returnUrl = '/dashboard';
        if (finance && !account) {
          url = '/setting/finance-settings';
        } else if (account && finance) {
          returnUrl = '/setting/finance-settings';
        }
        this.router.navigate([url], { queryParams: { returnUrl }});
      }
    }
  }

  constructor(
    private router: Router,
    private changes: ChangeDetectorRef,
    location: Location,
    private networkIndicator: NetworkIndicatorService,
    private authService: AuthService,
    private coreService: CoreService,
    private titleService: TitleService,
    // Required to initialize DecoratorService for use in method decorators
    _decoratorService: DecoratorService,
  ) {
    router.events.subscribe(() => {
      if (location.path() !== '') {
        this.route = location.path();
        this.params = this.route.split('/');
        if (this.params[this.params.length - 1] === 'login') {
          this.isLogin = true;
        }
      }
    });

    this.verifyIncompletePublisherProfile();
    authService.userUpdated$.subscribe(() => {
      this.verifyIncompletePublisherProfile();
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.titleService.clearTitle());

    this.authService.userUpdated$.subscribe(() => this.titleService.updateTitle());

    this.networkIndicator.indicatorActive$.subscribe((active) => {
      this.isNetworkIndicatorActive = active;
      this.changes.detectChanges();
    });
  }
}
